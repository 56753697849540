<template>
  <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user" >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">{{ userData.name }}</p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar size="40" :src="fileUrl(userData.avatar,'user',true)" variant="light-primary" badge class="badge-minimal" badge-variant="success">
        <feather-icon v-if="!fileUrl(userData.avatar,'user',true)" icon="UserIcon" size="22"/>
      </b-avatar>
    </template>

    <!-- <b-dropdown-item :to="{ name: 'pages-profile'}" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="UserIcon" class="mr-50"/>
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'apps-email' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="MailIcon" class="mr-50"/>
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'apps-todo' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="CheckSquareIcon" class="mr-50"/>
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'apps-chat' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="MessageSquareIcon" class="mr-50"/>
      <span>Chat</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Pricing</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->

    <b-dropdown-item link-class="d-flex align-items-center" @click="refresh">
      <feather-icon size="16" icon="RefreshCwIcon" class="mr-50" />
      <span>Refresh</span>
    </b-dropdown-item>

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
//import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { fileUrl, avatarText } from '@core/utils/filter'
//import { ability } from '@/libs/acl/ability'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem(useJwt.jwtConfig.storageUserDataName)),
      avatarText,
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      useJwt.logout()
      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    refresh() {
      //更新
      useJwt.refreshToken().then(r => {
        // Update ability
        if(r.data.user) useJwt.setUserData(r.data.user)
        // /if(r.data.user && r.data.user.ability) ability.update(r.data.user.ability)
        this.userData= JSON.parse(localStorage.getItem(useJwt.jwtConfig.storageUserDataName))

        this.$swal({
          icon: 'success',
          title: 'Updated',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
          customClass:{
            popup: 'rounded-lg'
          }
        })
      })
      .catch((error)=>{
        this.$swal({
          icon:'error',
          text: (error.response && error.response.data)?error.response.data.message:error.message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
          customClass:{
            popup: 'rounded-lg'
          }
        })
      })
    },
    fileUrl
  }
}
</script>
