export default [
  {
    title: 'security._',
    icon: ['far', 'shield-alt'],
    iconpack: 'fa',
    iconColor:'danger',
    tagVariant: 'light-warning',
    children: [
      // {
      //   header: 'Security',
      //   resource: 'Security',
      //   action: 'read'
      // }, 
      {
        title: 'security.clocking',
        route: 'security-clocking-list',
        icon: ['far', 'clock'],
        iconpack: 'fa',
        resource: 'Security',
        action: 'read'
      }, {
        title: 'security.report',
        route: 'security-report-list',
        icon: ['far', 'file-chart-line'],
        iconpack: 'fa',
        resource: 'Security',
        action: 'read'
      }, {
        title: 'security.route',
        route: 'security-route-list',
        icon: ['far', 'route'],
        iconpack: 'fa',
        resource: 'Security',
        action: 'read'
      }, {
        title: 'security.guard',
        route: 'security-guard-list',
        icon: ['fas', 'user-shield'],
        iconpack: 'fa',
        resource: 'Security',
        action: 'read'
      }, {
        title: 'security.device',
        route: 'security-device-list',
        icon: ['far', 'tablet-rugged'],
        iconpack: 'fa',
        resource: 'Security',
        action: 'read'
      }, {
        title: 'security.point',
        route: 'security-point-list',
        icon: ['fas', 'dot-circle'],
        iconpack: 'fa',
        resource: 'Security',
        action: 'read'
      }    
    ],
  },
]