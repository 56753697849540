import { render, staticRenderFns } from "./NotificationDropdown.vue?vue&type=template&id=55bf5871&"
import script from "./NotificationDropdown.vue?vue&type=script&lang=js&"
export * from "./NotificationDropdown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'directives', {Ripple});
