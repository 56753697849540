export default [
  {
    header: 'setting._',
    resource: 'Setting',
    action: 'read'
  },
  {
    title: 'Site Setting',
    route: 'setting',
    icon: ['far', 'cog'],
    iconpack: 'fa',
    resource: 'Setting',
    action: 'read',
  }
]