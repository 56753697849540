export default [
  {
    title: 'dashboard',
    icon: 'HomeIcon',
    route: 'dashboard',
    resource: 'Share',
    action: 'read'
  },
  {
    header: 'backend',
    resource: 'Share',
    action: 'read'
  }, 
]
