export default [ 
  {
    title: 'user._',
    route: 'user-list',
    icon: 'UserIcon',
    //icon: ['far', 'user'],
    //iconpack: 'fa',
    resource: 'User',
    action: 'read'
  }
]