export default [{
  title: 'supply-chain._',
  icon: ['far', 'conveyor-belt'],
  iconpack: 'fa',
  iconColor: 'primary',
  tag: '2',
  tagVariant: 'light-warning',
  children: [
    {
      title: 'supply-chain.product._',
      route: 'product-list',
      icon: ['far', 'boxes-alt'],
      iconpack: 'fa',
      resource: 'Product',
      action: 'read'
    }, {
      title: 'supply-chain.variant._',
      route: 'variant-list',
      icon: ['far', 'clone'],
      iconpack: 'fa',
      resource: 'Product',
      action: 'read'
    }, {
      title: 'supply-chain.inventory._',
      route: 'inventory-list',
      icon: ['far', 'inventory'],
      iconpack: 'fa',
      resource: 'Inventory',
      action: 'read'
    }, {
      title: 'supply-chain.purchase._',
      route: 'purchase-list',
      icon: ['far', 'shopping-cart'],
      iconpack: 'fa',
      resource: 'Purchase',
      action: 'read'
    }, {
      title: 'supply-chain.sale._',
      route: 'sale-list',
      icon: ['far', 'store'],
      iconpack: 'fa',
      resource: 'Sale',
      action: 'read'
    }
  ]}
]