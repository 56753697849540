export default [
  {
    title: 'finance._',
    icon: ['far', 'coins'],
    iconpack: 'fa',
    iconColor: 'warning',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'finance.balance_sheet',
        route: 'finance-account-list',
        icon: ['far', 'balance-scale'],
        iconpack: 'fa',
        resource: 'Finance',
        action: 'read'
      }, {
        title: 'finance.journals',
        route: 'finance-entry-list',
        icon: ['far', 'book'],
        iconpack: 'fa',
        resource: 'Finance',
        action: 'read'
      }, {
        title: 'finance.transaction.transactions',
        route: 'transaction-list',
        icon: ['far', 'sack-dollar'],
        iconpack: 'fa',
        resource: 'Finance',
        action: 'read'
      }, 
      // {
      //   title: 'Invoice',
      //   icon: 'FileTextIcon',
      //   children: [
      //     {
      //       title: 'List',
      //       route: 'finance-invoice-list',
      //       resource: 'Invoice',
      //       action: 'read'
      //     },
      //     {
      //       title: 'Preview',
      //       route: { name: 'finance-invoice-preview', params: { id: 4987 } },
      //       resource: 'Invoice',
      //       action: 'read'
      //     },
      //     {
      //       title: 'Edit',
      //       route: { name: 'finance-invoice-edit', params: { id: 4987 } },
      //       resource: 'Invoice',
      //       action: 'update'
      //     },
      //     {
      //       title: 'Add',
      //       route: { name: 'finance-invoice-add' },
      //       resource: 'Invoice',
      //       action: 'create'
      //     },
      //   ],
      // }
    ]
  }
]