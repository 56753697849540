export default [
  {
    title: 'member._',
    icon: ['far', 'users'],
    iconpack: 'fa',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'member.supplier._',
        route: 'member-supplier-list',
        // icon: ['fas', 'people-carry'],
        // iconpack: 'fa',
        resource: 'Supplier',
        action: 'read'
      }, {
        title: 'member.customer._',
        route: 'member-customer-list',
        // icon: ['fas', 'users'],
        // iconpack: 'fa',
        resource: 'Customer',
        action: 'read'
      }, {
        title: 'member.customer.structure',
        route: 'member-customer-structure',
        // icon: ['fas', 'users'],
        // iconpack: 'fa',
        resource: 'Customer',
        action: 'read'
      }, {
        title: 'member.customer_trans._',
        route: 'member-customer-trans-list',
        // icon: ['fas', 'users'],
        // iconpack: 'fa',
        resource: 'Customer',
        action: 'read'
      }
    ]
  }
]